import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  logs: [],
  freshLogId: null,
  detail: {},
}

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState
    },
    setApiLogs: (state, action) => {
      state.logs = action.payload
    },
    addApiLogs: (state, action) => {
      state.logs = [...state.logs, ...action.payload].sort((a, b) => b.id - a.id)
    },
    setFreshLogId: (state, action) => {
      state.freshLogId = action.payload
    },
    setApiDetail: (state, action) => {
      state.detail = action.payload
    },
  },
})

export const apiReducerActions = apiSlice.actions

export default apiSlice.reducer
