import { createSlice } from '@reduxjs/toolkit'

const initialApi = {}

const apiTestSlice = createSlice({
  name: 'apiTest',
  initialState: {
    api: initialApi,
  },
  reducers: {
    updateApi: (state, action) => {
      state.api = { ...state.api, ...action.payload }
    },
    setInitialApi: state => {
      state.api = initialApi
    },
  },
})

export const apiTestReducerActions = apiTestSlice.actions

export default apiTestSlice.reducer
