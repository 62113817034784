// We should add it
// eslint-disable-next-line no-unused-vars
import ApexCharts from 'apexcharts'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Suspense, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

import OfflinePage from '@/components/OfflinePage/OfflinePage'
import SuspenseLoading from '@/components/SuspenseLoading'
import useOnlineStatus from '@/hooks/useOnlineStatus'
import router from '@/router'

const SuspenseFallback = () => {
  return <SuspenseLoading />
}

function App() {
  const { isOnline } = useOnlineStatus()

  const handleRedirectToMobileApp = () => {
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
    if (isMobile) {
      const deepLink = `Inavoconsole:/${window.location.pathname}`
      window.location.href = deepLink
    }
  }

  useEffect(() => {
    dayjs.extend(customParseFormat)
    dayjs.locale('tr') // We should extend with other languages

    handleRedirectToMobileApp()
  }, [])

  return (
    <Suspense fallback={<SuspenseFallback />}>
      {!isOnline && <OfflinePage />}
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default App
