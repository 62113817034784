import { clsx } from 'clsx'
import { useRef, useState } from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { IoHelpCircleOutline } from 'react-icons/io5'
import { RiUserSettingsLine, RiUserStarLine } from 'react-icons/ri'
import { TbPassword } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import UserAvatar, { PROFILE_IMAGE_SIZES } from '@/components/UserAvatar/UserAvatar'
import { INAVO_DEV_URL } from '@/constants'
import { ROUTE_URLS } from '@/constants/routeUrls'
import UserSettingsPopup from '@/containers/Root/Header/UserSettingsPopup/UserSettingsPopup'
import UserUpdatePasswordPopup from '@/containers/Root/Header/UserUpdatePasswordPopup/UserUpdatePasswordPopup'
import CloseButton from '@/containers/Root/components/CloseButton/CloseButton'
import Search from '@/containers/Root/components/Search/Search'
import useClickOutside from '@/hooks/useClickOutside'
import usePermission from '@/hooks/usePermission'
import { appReducerActions } from '@/store/reducers/app'
import { isMobile } from '@/utils'

import './header.scss'

const Header = () => {
  const [isEnabledUserDropdown, setIsEnabledUserDropdown] = useState(false)
  const [isEnabledUserUpdatePasswordPopup, setIsEnabledUserUpdatePasswordPopup] = useState(false)

  const headerUserSettingsRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isProjectAdmin } = usePermission()
  const { user, isEnabledUserSettingsDropdown } = useSelector(state => {
    const { user } = state.auth
    const { isEnabledUserSettingsDropdown } = state.app

    return { user, isEnabledUserSettingsDropdown }
  })

  useClickOutside(headerUserSettingsRef, () => {
    setIsEnabledUserDropdown(false)
  })

  const handleOnClickUserSettings = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(true))
  }

  const renderUserImage = () => {
    const userImage = user.image

    return <UserAvatar image={userImage} size={PROFILE_IMAGE_SIZES.SMALL} />
  }

  const handleOnClickUserButton = () => {
    setIsEnabledUserDropdown(!isEnabledUserDropdown)
  }

  const handleCloseUserSettingsPopup = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(false))
  }

  const handleOnClickUserUpdatePassword = () => {
    setIsEnabledUserUpdatePasswordPopup(true)
  }

  const handleCloseUserUpdatePassword = () => {
    setIsEnabledUserUpdatePasswordPopup(false)
  }

  const handleOnClickCreateApiButton = () => {
    navigate(ROUTE_URLS.CHOOSE_CREATE_API_TYPE)
  }

  const handleOnClickUserDropdownItem = fn => () => {
    fn()
    setIsEnabledUserDropdown(false)
  }

  const handleRedirect = url => () => {
    navigate(url)
  }

  return (
    <div className='header'>
      <UserSettingsPopup isVisible={isEnabledUserSettingsDropdown} handleClosePopup={handleCloseUserSettingsPopup} />
      <UserUpdatePasswordPopup isVisible={isEnabledUserUpdatePasswordPopup} handleClosePopup={handleCloseUserUpdatePassword} />
      <div className='header__item header__search-wrapper'>
        <Search />
      </div>
      {isProjectAdmin && !isMobile() && (
        <div onClick={handleOnClickCreateApiButton} className='header__item header__create-api-btn'>
          Aksiyon Oluştur
        </div>
      )}
      <div ref={headerUserSettingsRef} className='header__item header__user'>
        <div
          onClick={handleOnClickUserButton}
          className={clsx('header__user-button', { 'header__user-button--enabled': isEnabledUserDropdown })}>
          {renderUserImage()}
          <div className='header__user-name'>{user.name}</div>
        </div>
        <div className={clsx('header__user-dropdown', { 'header__user-dropdown--enabled': isEnabledUserDropdown })}>
          <div className='header__user-dropdown-section'>
            <div className='header__user-dropdown-info'>
              <UserAvatar image={user.image} size={PROFILE_IMAGE_SIZES.MEDIUM} />
              <div className='header__user-dropdown-info-texts'>
                <div className='header__user-dropdown-name'>{user.name}</div>
                <div className='header__user-dropdown-email'>{user.email}</div>
              </div>
            </div>
          </div>
          <div className='header__user-dropdown-section'>
            <div onClick={handleOnClickUserDropdownItem(handleOnClickUserSettings)} className='header__user-item'>
              <RiUserSettingsLine className='header__user-item-icon' />
              <span>Hesap Ayarları</span>
            </div>
            <div onClick={handleOnClickUserDropdownItem(handleOnClickUserUpdatePassword)} className='header__user-item'>
              <TbPassword className='header__user-item-icon' />
              <span>Şifre Değiştir</span>
            </div>
          </div>
          <div className='header__user-dropdown-section'>
            <div onClick={handleOnClickUserDropdownItem(handleRedirect(ROUTE_URLS.PROJECT_USER_IMPRINT))} className='header__user-item'>
              <RiUserStarLine className='header__user-item-icon' />
              <span>Kullanıcı Künyesi</span>
            </div>
            <a href={INAVO_DEV_URL} target='_blank' className='header__user-item'>
              <IoHelpCircleOutline className='header__user-item-icon' />
              <span>Döküman</span>
            </a>
          </div>
          <div className='header__user-dropdown-section header__user-dropdown-section--disable-border-bottom'>
            <div className='header__user-item'>
              <CloseButton>
                <span className='header__close-button-content'>
                  <IoIosLogOut className='header__user-item-icon' />
                  Çıkış Yap
                </span>
              </CloseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
