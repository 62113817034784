import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import { postProject } from '@/api'
import FormItem from '@/components/FormItem/FormItem'
import Input from '@/components/Input/Input'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import Textarea from '@/components/Textarea/Textarea'
import { appReducerActions } from '@/store/reducers/app'
import { setActiveProjectId } from '@/utils/localStorageActions'

import './createProjectPopup.scss'

const CreateProjectPopup = ({ isVisible, handleClosePopup }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [projectDetail, setProjectDetail] = useState({ name: '', apiBaseUrl: '', description: '' })

  const dispatch = useDispatch()

  const isValidProjectDetail = projectDetail.name !== '' && projectDetail.apiBaseUrl !== ''

  const handleChangeProjectDetail = detail => setProjectDetail({ ...projectDetail, ...detail })

  const handleChangeInput = detailKey => e => handleChangeProjectDetail({ [detailKey]: e.target.value })

  const handleChangeClickCreateButton = async () => {
    try {
      setIsLoading(true)

      const projectRes = await postProject(projectDetail)
      const project = projectRes.data
      const projectId = project.id

      dispatch(
        appReducerActions.addProject({
          ...project,
          isAdmin: true,
        }),
      )
      dispatch(appReducerActions.setActiveProjectId(projectId))
      setActiveProjectId(projectId)

      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Popup title='Proje Oluştur' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='create-project'>
        <div className='create-project__list'>
          <FormItem className='create-project__item' label='Proje İsmi*'>
            <Input className='create-project__input' value={projectDetail.name} onChange={handleChangeInput('name')} />
          </FormItem>
          <FormItem className='create-project__item' label='URL*'>
            <Input className='create-project__input' value={projectDetail.apiBaseUrl} onChange={handleChangeInput('apiBaseUrl')} />
          </FormItem>
          <FormItem className='create-project__item' label='Açıklama'>
            <Textarea className='create-project__input' value={projectDetail.description} onChange={handleChangeInput('description')} />
          </FormItem>
        </div>
        <PrimaryButton
          onClick={handleChangeClickCreateButton}
          isLoading={isLoading}
          disable={!isValidProjectDetail}
          className='create-project__create-btn'>
          Oluştur
        </PrimaryButton>
      </div>
    </Popup>
  )
}

export default CreateProjectPopup
