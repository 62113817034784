import { useLocation } from 'react-router-dom'

/* eslint-disable react/display-name */
const withForceRerender = WrapperComponent => props => {
  const { pathname, search } = useLocation()

  const key = `${pathname}_${search}`

  return <WrapperComponent key={key} {...props} />
}

export default withForceRerender
