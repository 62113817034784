import { RiForbid2Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'

import './permissionError.scss'

const PermissionError = () => {
  const navigate = useNavigate()

  const handleOnClickBackHome = () => {
    navigate(ROUTE_URLS.HOME)
  }

  return (
    <div className='permission-error'>
      <RiForbid2Fill className='permission-error__icon' />
      <div className='permission-error__title'>İzinsiz Alan</div>
      <div className='permission-error__desc'>
        Bu alana erişmek için izniniz yok. Hata olduğunu düşünüyosanız yöneticiniz ile iletişime geçiniz.
      </div>
      <div onClick={handleOnClickBackHome} className='permission-error__back-home'>
        Ana Sayfa Git
      </div>
    </div>
  )
}

export default PermissionError
