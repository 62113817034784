import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'
import MobileRoot from '@/containers/Root/MobileRoot/MobileRoot'
import Root from '@/containers/Root/Root'
import ErrorBoundaryWrapper from '@/router/ErrorBoundaryWrapper'
import withAuthenticated from '@/router/withAuthenticated'
import withForceRerender from '@/router/withForceRerender'
import withNotAuthenticated from '@/router/withNotAuthenticated'
import withRootErrorBoundary from '@/router/withRootErrorBoundary'
import withUserPermission from '@/router/withUserPermission'
import withWrapperRootLogic from '@/router/withWrapperRootLogic'
import { isMobile } from '@/utils'

// Lazy load components
const Home = lazy(() => import('@/pages/Home/Home'))
const Users = lazy(() => import('@/pages/Users/Users'))
const ActionGroupPermissions = lazy(() => import('@/pages/ActionGroupPermissions/ActionGroupPermissions'))
const ApiExecute = lazy(() => import('@/pages/ApiExecute/ApiExecute'))
const ProjectLogs = lazy(() => import('@/pages/ProjectLogs/ProjectLogs'))
const ProjectSettings = lazy(() => import('@/pages/ProjectSettings/ProjectSettings'))
const ProjectUserImprint = lazy(() => import('@/pages/ProjectUserImprint/ProjectUserImprint'))
const ProjectUserNotifications = lazy(() => import('@/pages/ProjectUserNotifications/ProjectUserNotifications'))
const Charts = lazy(() => import('@/pages/Charts/Charts'))
const Tables = lazy(() => import('@/pages/Tables/Tables'))
const ProjectUserPermissions = lazy(() => import('@/pages/ProjectUserPermissions/ProjectUserPermissions'))
const Actions = lazy(() => import('@/pages/Actions/Actions'))
const BuildApi = lazy(() => import('@/containers/BuildApi/BuildApi'))
const ChangePassword = lazy(() => import('@/containers/ChangePassword/ChangePassword'))
const ForgetPassword = lazy(() => import('@/containers/ForgetPassword/ForgetPassword'))
const Login = lazy(() => import('@/containers/Login/Login'))
const PostmanBuilder = lazy(() => import('@/containers/PostmanBuilder/PostmanBuilder'))
const Register = lazy(() => import('@/containers/Register/Register'))
const NotFoundError = lazy(() => import('@/pages/NotFoundError/NotFoundError'))
const ChooseCreateApiType = lazy(() => import('@/pages/ChooseCreateApiType/ChooseCreateApiType'))

const AuthenticatedRoot = withAuthenticated(Root)
const AuthenticatedMobileRoot = withAuthenticated(MobileRoot)
const NotAuthenticatedLogin = withNotAuthenticated(Login)
const NotAuthenticatedRegister = withNotAuthenticated(Register)
const NotAuthenticatedForgetPassword = withNotAuthenticated(ForgetPassword)
const NotAuthenticatedChangePassword = withNotAuthenticated(ChangePassword)
const _BuildApi = withWrapperRootLogic(withUserPermission(BuildApi))
const _PostmanBuilder = withWrapperRootLogic(PostmanBuilder)
const _ChooseCreateApiType = withWrapperRootLogic(ChooseCreateApiType)

const WrapperPageContent = WrapperComponent => withUserPermission(withRootErrorBoundary(WrapperComponent))

const _Home = withRootErrorBoundary(Home)
const _Users = WrapperPageContent(Users)
const _ActionGroupPermissions = WrapperPageContent(ActionGroupPermissions)
const _ApiExecute = withForceRerender(WrapperPageContent(ApiExecute))
const _ProjectLogs = WrapperPageContent(ProjectLogs)
const _ProjectSettings = WrapperPageContent(ProjectSettings)
const _ProjectUserImprint = WrapperPageContent(ProjectUserImprint)
const _ProjectUserNotifications = WrapperPageContent(ProjectUserNotifications)
const _Charts = WrapperPageContent(Charts)
const _Tables = WrapperPageContent(Tables)
const _ProjectUserPermissions = WrapperPageContent(ProjectUserPermissions)
const _Actions = WrapperPageContent(Actions)

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper />,
    children: [
      {
        path: '/',
        element: isMobile() ? <AuthenticatedMobileRoot /> : <AuthenticatedRoot />,
        children: [
          { path: ROUTE_URLS.HOME, element: <_Home /> },
          { path: ROUTE_URLS.USERS, element: <_Users /> },
          { path: ROUTE_URLS.ACTION_GROUP_PERMISSIONS, element: <_ActionGroupPermissions /> },
          {
            path: ROUTE_URLS.API_EXECUTE,
            element: <_ApiExecute />,
          },
          { path: ROUTE_URLS.PROJECT_LOGS, element: <_ProjectLogs /> },
          { path: ROUTE_URLS.PROJECT_SETTINGS, element: <_ProjectSettings /> },
          { path: ROUTE_URLS.PROJECT_USER_IMPRINT, element: <_ProjectUserImprint /> },
          { path: ROUTE_URLS.PROJECT_USER_NOTIFICATIONS, element: <_ProjectUserNotifications /> },
          { path: ROUTE_URLS.CHARTS, element: <_Charts /> },
          { path: ROUTE_URLS.TABLES, element: <_Tables /> },
          { path: ROUTE_URLS.PROJECT_USER_PERMISSIONS, element: <_ProjectUserPermissions /> },
          { path: ROUTE_URLS.ACTIONS, element: <_Actions /> },
        ],
      },
      {
        path: ROUTE_URLS.BUILD_API,
        element: <_BuildApi />,
      },
      {
        path: ROUTE_URLS.POSTMAN_BUILDER,
        element: <_PostmanBuilder />,
      },
      {
        path: ROUTE_URLS.LOGIN,
        element: <NotAuthenticatedLogin />,
      },
      {
        path: ROUTE_URLS.REGISTER,
        element: <NotAuthenticatedRegister />,
      },
      {
        path: ROUTE_URLS.FORGET_PASSWORD,
        element: <NotAuthenticatedForgetPassword />,
      },
      {
        path: ROUTE_URLS.CHANGE_PASSWORD,
        element: <NotAuthenticatedChangePassword />,
      },
      {
        path: ROUTE_URLS.CHOOSE_CREATE_API_TYPE,
        element: <_ChooseCreateApiType />,
      },
      {
        path: '*',
        element: <NotFoundError />,
      },
    ],
  },
])

export default router
