import InavoIcon from '@/assets/logos/inavo.svg'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './errorPage.scss'

const ErrorPage = ({ resetError }) => {
  const handleOnClickRetry = () => {
    resetError()
  }

  return (
    <div className='error-page'>
      <div className='error-page__content'>
        <img src={InavoIcon} className='error-page__icon' />
        <div className='error-page__text'>Bir hata oluştu, bununla ilgileniyoruz.</div>
        <PrimaryButton className='error-page__retry-button' onClick={handleOnClickRetry}>
          Tekrar Yükle
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ErrorPage
