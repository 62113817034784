import { configureStore } from '@reduxjs/toolkit'

import api from '@/store/reducers/api'
import apiCollection from '@/store/reducers/apiCollection'
import apiTest from '@/store/reducers/apiTest'
import app from '@/store/reducers/app'
import auth from '@/store/reducers/auth'
import buildApi from '@/store/reducers/buildApi'
import importApiCollection from '@/store/reducers/importApiCollection'
import postmanBuilder from '@/store/reducers/postmanBuilder'

export default configureStore({
  reducer: {
    importApiCollection,
    auth,
    app,
    buildApi,
    postmanBuilder,
    apiCollection,
    apiTest,
    api,
  },
})
