import clsx from 'clsx'

import './formItem.scss'

const FormItem = ({ label, children, errorMessage, className }) => {
  return (
    <div className={clsx('form-item', className)}>
      <div className='form-item__text'>{label}</div>
      <div className='form-item__content'>{children}</div>
      {errorMessage && <div className='form-item__error-message'>{errorMessage}</div>}
    </div>
  )
}

export default FormItem
