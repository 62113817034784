import { v4 as uuid } from 'uuid'

import { DEFAULT_COLOR } from '@/components/ColorPickerInput/ColorPickerInput'
import { DEFAULT_VALUES as ToggleSwitchDefaultValues } from '@/components/FormFieldComponents/ToggleSwitch/ToggleSwitch'
import {
  ADVANCED_FIELD_TYPE_VALUES,
  API_METHODS,
  FIELD_GROUP_TYPES,
  FIELD_SCOPE_TYPES,
  FIELD_TYPES,
  GENERAL_FIELD_TYPE_VALUES,
} from '@/constants'

const getDefaultValue = type => {
  switch (type) {
    case FIELD_TYPES.BEAUTIFY_JSON:
      return {}
    case FIELD_TYPES.TOGGLE_SWITCH:
      return ToggleSwitchDefaultValues.OFF
    case FIELD_TYPES.COLOR_PICKER:
      return DEFAULT_COLOR
    default:
      return ''
  }
}

const getFieldScopeType = type => {
  if (ADVANCED_FIELD_TYPE_VALUES.includes(type)) {
    return FIELD_SCOPE_TYPES.GENERAL
  }

  return FIELD_SCOPE_TYPES.BODY
}

const getDefaultFieldObject = itemType => ({
  type: itemType,
  key: '',
  label: '',
  id: uuid(),
  defaultValue: getDefaultValue(itemType),
  fieldScopeType: getFieldScopeType(itemType),
})
const getFieldPropertiesObject = itemType => {
  const defaultProperties = { invisible: false, isDependency: false, rules: [], isNotIncluded: false } // Some properties can be not included default properties due it is added after time.

  switch (itemType) {
    case FIELD_TYPES.SELECT:
      return {
        ...defaultProperties,
        choices: [],
      }
    case FIELD_TYPES.REMOTE_SELECT:
      return {
        ...defaultProperties,
        url: '',
        header: {},
        method: API_METHODS.GET,
        dataKey: '',
        labelKey: '',
        valueKey: '',
        dependencyKeys: [],
        searchQueryParameter: '',
      }
    case FIELD_TYPES.TOGGLE_SWITCH:
      return {
        ...defaultProperties,
        onValue: ToggleSwitchDefaultValues.ON,
        offValue: ToggleSwitchDefaultValues.OFF,
      }
    case FIELD_TYPES.FILE:
      return {
        ...defaultProperties,
        onlyAccepts: [],
        isMultiple: false,
      }
    case FIELD_TYPES.EDIT_TABLE:
      return {
        ...defaultProperties,
        table: {
          label: '',
          url: '',
          header: {},
          method: API_METHODS.GET,
          dataKey: '',
          limitKey: '',
          totalKey: '',
          properties: {},
        },
        columns: [],
        actions: [],
        filters: [], // It is not included in old fields.
      }
    default:
      return { ...defaultProperties }
  }
}

export const getFieldObject = payload => {
  const { type, customFieldObject = {} } = payload

  const defaultObject = getDefaultFieldObject(type)
  const properties = getFieldPropertiesObject(type)

  return {
    ...defaultObject,
    properties,
    ...customFieldObject,
  }
}

export const getFieldText = fieldType => {
  switch (fieldType) {
    case FIELD_TYPES.STRING:
      return 'Kısa Metin'
    case FIELD_TYPES.TEXTAREA:
      return 'Uzun Metin'
    case FIELD_TYPES.NUMBER:
      return 'Sayı'
    case FIELD_TYPES.PASSWORD:
      return 'Şifre'
    case FIELD_TYPES.SELECT:
      return 'Açılır Liste'
    case FIELD_TYPES.REMOTE_SELECT:
      return 'Dinamik Açılır Liste'
    case FIELD_TYPES.IMAGE:
      return 'Fotoğraf'
    case FIELD_TYPES.RICH_TEXT:
      return 'Düzenlenebilir Metin'
    case FIELD_TYPES.TOGGLE_SWITCH:
      return 'Açılır/Kapanır Buton'
    case FIELD_TYPES.DATE:
      return 'Tarih Seçici'
    case FIELD_TYPES.HOUR:
      return 'Saat Seçici'
    case FIELD_TYPES.FILE:
      return 'Dosya Yükleme'
    case FIELD_TYPES.COLOR_PICKER:
      return 'Renk Seçici'
    case FIELD_TYPES.BEAUTIFY_JSON:
      return 'JSON Düzenleyici'
    case FIELD_TYPES.EDIT_TABLE:
      return 'Tablo Düzenleyici'
    default:
      return 'Bilinmeyen Eleman'
  }
}

export const getApiObject = payload => ({
  method: '',
  url: '',
  title: '',
  description: '',
  fields: [],
  header: {},
  defaultBody: {},
  otherFields: {},
  defaultUrlQueryParams: {},
  configs: {},
  apiGroupId: null,
  ...payload,
})

export const getFieldValuesByGroupType = groupType => {
  switch (groupType) {
    case FIELD_GROUP_TYPES.GENERAL:
      return GENERAL_FIELD_TYPE_VALUES
    case FIELD_GROUP_TYPES.ADVANCED:
      return ADVANCED_FIELD_TYPE_VALUES
  }
}
