import clsx from 'clsx'

import InavoLoading from '@/components/InavoLoading/InavoLoading'

import './outlinedButton.scss'

const OutlinedButton = ({ children, className, isLoading, disable, loadingClassName, ...otherProps }) => {
  const allClassnames = clsx('outlined-button', className, {
    'primary-button--disable': disable || isLoading,
  })

  return (
    <button className={allClassnames} {...otherProps}>
      {isLoading ? <InavoLoading className={clsx('outlined-button--loading', loadingClassName)} /> : children}
    </button>
  )
}

export default OutlinedButton
