import clsx from 'clsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Input from '@/components/Input/Input'
import { filterText as filterTextUtil } from '@/utils'

import './search.scss'

const SEARCH_ITEMS = [
  {
    title: 'Ana Sayfa',
    description: 'Ana Sayfa',
    redirectUrl: '/',
  },
  {
    title: 'Ayarları Güncelle',
    description: 'Bu sayfada proje ayarlarını güncelleyebilirsiniz',
    redirectUrl: '/project-settings',
  },
  {
    title: 'Kullanıcılar',
    description: 'Bu sayfada proje kullanıcılarını görüntüleyebilirsiniz',
    redirectUrl: '/users',
  },
  {
    title: 'Kullanıcı Ekle',
    description: 'Bu sayfada proje kullanıcısı ekleyebilirsiniz',
    redirectUrl: '/users',
  },
  {
    title: 'Kullanıcı Güncelle',
    description: 'Bu sayfada proje kullanıcısı güncelleyebilirsiniz',
    redirectUrl: '/users',
  },
  {
    title: 'Kullanıcı Sil',
    description: 'Bu sayfada proje kullanıcısı silebilirsiniz',
    redirectUrl: '/users',
  },
  {
    title: 'Hareketler',
    description: 'Bu sayfada proje hareketlerini/log"larını görüntüleyebilirsiniz',
    redirectUrl: '/project-logs',
  },
  {
    title: 'Proje Yetkileri',
    description: 'Bu sayfada proje yetkilerini görüntüleyebilirsiniz',
    redirectUrl: '/project-user-permissions',
  },
  {
    title: 'Proje Yetkisini Güncelle',
    description: 'Bu sayfada proje yetkilerini güncelleyebilirsiniz',
    redirectUrl: '/project-user-permissions',
  },
  {
    title: 'Aksiyon Yetkileri',
    description: 'Bu sayfada aksiyon yetkilerini görüntüleyebilirsiniz',
    redirectUrl: '/action-group-permissions',
  },
  {
    title: 'Aksiyon Yetkisi Sil',
    description: 'Bu sayfada aksiyon yetkisi silebilirsiniz',
    redirectUrl: '/action-group-permissions',
  },
  {
    title: 'Aksiyon Yetkisi Ekle',
    description: 'Bu sayfada aksiyon yetkisi ekleyebilirsiniz',
    redirectUrl: '/action-group-permissions',
  },
  {
    title: 'Aksiyon Yetkisi Güncelle',
    description: 'Bu sayfada aksiyon yetkisi güncelleyebilirsiniz',
    redirectUrl: '/action-group-permissions',
  },
  {
    title: 'Grafikler',
    description: 'Bu sayfada garfikleri görüntüleyebilirsiniz',
    redirectUrl: '/charts',
  },
  {
    title: 'Aksiyon Oluştur',
    description: 'Bu sayfada aksiyon oluşturabilirsiniz',
    redirectUrl: '/build-api',
  },
  {
    title: 'Tablolar',
    description: 'Bu sayfada tabloları görüntüleyebilirsiniz',
    redirectUrl: '/tables',
  },
  {
    title: 'Tablo Ekle',
    description: 'Bu sayfada tablo ekleyebilirsiniz',
    redirectUrl: '/tables',
  },
  {
    title: 'Tablo Sil',
    description: 'Bu sayfada tablo silebilirsiniz',
    redirectUrl: '/tables',
  },
  {
    title: 'Tablo Güncelle',
    description: 'Bu sayfada tablo güncelleyebilirsiniz',
    redirectUrl: '/tables',
  },
]

const Search = () => {
  const [filterText, setFilterText] = useState('')
  const [isEnabledDropdown, setIsEnabledDropdown] = useState(false)

  const navigate = useNavigate()

  const isEmptyFilterText = filterText === ''
  const filteredItems = SEARCH_ITEMS.filter(
    ({ title, description }) => filterTextUtil(filterText, title) || filterTextUtil(filterText, description),
  )
  const hasFilteredItems = filteredItems.length > 0

  const handleOnFocus = () => {
    setIsEnabledDropdown(true)
  }

  const handleOnBlur = () => {
    setIsEnabledDropdown(false)
  }

  const handleOnChange = e => {
    setFilterText(e.target.value)
  }

  const handleOnClickItem = redirectUrl => () => {
    navigate(redirectUrl)
    setFilterText('')
  }

  const getContent = () => {
    switch (true) {
      case isEmptyFilterText:
        return <div className='header-search__no-filter-text'>En az 1 karaketer girilmelidir</div>
      case !hasFilteredItems:
        return <div className='header-search__no-filter-text'>Hiç sonuç bulamadık</div>
      default:
        return (
          <>
            {filteredItems.map(({ title, description, redirectUrl }, key) => (
              <div onClick={handleOnClickItem(redirectUrl)} key={key} className='header-search__item'>
                <div className='header-search__title'>{title}</div>
                <div className='header-search__description'>{description}</div>
              </div>
            ))}
          </>
        )
    }
  }

  return (
    <div className='header-search'>
      <Input
        value={filterText}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        className='header-search__input'
        placeholder='Ara...'
      />
      <div className={clsx('header-search__dropdown', { 'header-search__dropdown--enabled': isEnabledDropdown })}>{getContent()}</div>
    </div>
  )
}

export default Search
