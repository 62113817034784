import clsx from 'clsx'
import { forwardRef } from 'react'

import './input.scss'

export const INPUT_VARIANTS = {
  DEFAULT: 'default',
  BOLD: 'bold',
}

// eslint-disable-next-line react/display-name
const Input = forwardRef(({ className, variant = INPUT_VARIANTS.DEFAULT, ...otherProps }, ref) => {
  const allClassnames = clsx('input-component', className, `input-component--${variant}`)

  return <input className={allClassnames} type='string' {...otherProps} ref={ref} />
})

export default Input
