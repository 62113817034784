import clsx from 'clsx'

import InavoMan from '@/assets/icons/man.png'

import './userAvatar.scss'

export const PROFILE_IMAGE_SIZES = {
  SMALL: 'sm',
  MEDIUM: 'md',
  XXL: 'xxl',
}

const UserAvatar = ({ image, size = PROFILE_IMAGE_SIZES.MEDIUM }) =>
  image ? (
    <img className={clsx('user-avatar__img', `user-avatar__img--${size}`)} src={image} />
  ) : (
    <img src={InavoMan} className={clsx('user-avatar__default-img', `user-avatar__default-img--${size}`)} />
  )

export default UserAvatar
