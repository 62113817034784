import { useEffect } from 'react'

const useEventListener = ({ event, handleListener, isActive = true }, dependencies) => {
  useEffect(() => {
    if (isActive) {
      document.addEventListener(event, handleListener)
    }

    return () => {
      document.removeEventListener(event, handleListener)
    }
  }, dependencies)
}

export default useEventListener
